import request from "@/utils/request";
// 查询当前数据报表
export function findHomeCount(parameter) {
  return request({
    url: '/api/report-info/homeCount',
    method: 'post',
    data: parameter,
  })
}

