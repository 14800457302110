<template>
  <div>
    <div class="title">
      DR.BODY SCAN全国总计筛选量
      <div class="time">{{ time }}</div>
    </div>
    <div class="content">
      <el-card header="省份" class="parent_card" shadow="hover">
        <el-row :gutter="20">
          <el-col :span="3" v-for="item in provinceList" :key="item.name">
            <el-card class="col_card" shadow="hover">
              <el-statistic group-separator="," :value="parseInt(item.count)" :title="item.name" />
            </el-card>
          </el-col>
        </el-row>
      </el-card>

      <el-card header="检测结果" class="parent_card" shadow="hover">
        <el-row :gutter="20">
          <el-col :span="3" v-for="item in reportDataList" :key="item.name">
            <el-card class="col_card" shadow="hover">
              <el-statistic group-separator="," :value="parseInt(item['count(id)'])" :title="item.name" />
            </el-card>
          </el-col>
        </el-row>
      </el-card>

      <el-card header="性别" class="parent_card" shadow="hover">
        <el-row :gutter="20">
          <el-col :span="3" v-for="item in genderList" :key="item.name">
            <el-card class="col_card" shadow="hover">
              <el-statistic group-separator="," :value="parseInt(item.count)" :title="item.name" />
            </el-card>
          </el-col>
        </el-row>
      </el-card>
    </div>
  </div>
</template>
<script>
import { findHomeCount } from '@/api/common.js';
export default {
  data() {
    return {
      genderList: [],
      provinceList: [],
      reportDataList: [],
      time: new Date().Format('yyyy-MM-dd hh:mm:ss')
    };
  },
  created() {
    this.findHomeCount();
  },
  methods: {
    // 开始查询
    findHomeCount() {
      const rLoading = this.openLoading(); // 开始loading
      findHomeCount({})
        .then((res) => {
          if (res.data.status == 200) {
            this.genderList = res.data.data.genderList;
            this.provinceList = res.data.data.provinceList;
            this.reportDataList = res.data.data.reportDataList;
            this.time = new Date(parseInt(res.data.timestamp)).Format(
              'yyyy-MM-dd hh:mm:ss'
            );
            rLoading.close(); // 关闭loading
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((res) => {
          this.$message.error(res);
        });
    }
  }
};
</script>
<style scoped lang="stylus">
.title
    width: 100%
    text-align: center
    margin-bottom: 30px
    .time
      font-size: 20px

.content 
    .parent_card 
      margin-bottom: 30px
      .col_card 
        margin-bottom: 10px
</style>
